.countrySelector {
    font-family: 'Ubuntu Condensed', sans-serif !important;
    font-size: 1rem;
    line-height: 1.5;
    color: #495057;
    height: 38px;
  }

  .smallFont {
    font-size: 80%
  }

  .fullwidth {
    width: 100%;
}